var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "fill-height" },
    [
      _c(
        "v-col",
        [
          _c(
            "v-sheet",
            { attrs: { height: "114" } },
            [
              _vm.loading
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "text-caption text-center" }, [
                          _vm._v("Loading vehicles..."),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "mb-5", attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value:
                                _vm.vehicleCalendar.requestingLocationFilter,
                              items: _vm.locations,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Requesting Location",
                              outlined: "",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setFilter(
                                  $event,
                                  "requestingLocationFilter"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value: _vm.vehicleCalendar.vehicleLocationFilter,
                              items: _vm.vehicleLocations,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Vehicle Location",
                              outlined: "",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setFilter(
                                  $event,
                                  "vehicleLocationFilter"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value: _vm.vehicleCalendar.vehicleTypeFilter,
                              items: _vm.vehicleTypes,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Vehicle Type",
                              outlined: "",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setFilter(
                                  $event,
                                  "vehicleTypeFilter"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value: _vm.vehicleCalendar.tripTypeFilter,
                              items: _vm.tripTypes,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Trip Type",
                              outlined: "",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setFilter($event, "tripTypeFilter")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value: _vm.vehicleCalendar.zoneFilter,
                              items: _vm.zones,
                              label: "Zone",
                              outlined: "",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setFilter($event, "zoneFilter")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value: _vm.vehicleCalendar.vehicleFilter,
                              items: _vm.vehicles,
                              "item-text": "name",
                              "item-value": "id",
                              label: "Vehicle",
                              outlined: "",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setFilter($event, "vehicleFilter")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-select", {
                            attrs: {
                              value: _vm.vehicleCalendar.vehicleOwnerFilter,
                              items: _vm.vehicleOwnerItems,
                              "item-text": "name",
                              "item-value": "email",
                              label: "Vehicle Owner",
                              outlined: "",
                              clearable: "",
                              dense: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setFilter(
                                  $event,
                                  "vehicleOwnerFilter"
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                    ],
                    1
                  ),
              _c(
                "v-toolbar",
                { staticClass: "tbar", attrs: { flat: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { outlined: "", color: "grey darken-2" },
                      on: { click: _vm.setToday },
                    },
                    [_vm._v(" Today ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        text: "",
                        small: "",
                        color: "grey darken-2",
                      },
                      on: { click: _vm.prev },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-chevron-left "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        fab: "",
                        text: "",
                        small: "",
                        color: "grey darken-2",
                      },
                      on: { click: _vm.next },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-chevron-right "),
                      ]),
                    ],
                    1
                  ),
                  _c("v-toolbar-title", [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-tooltip",
                    {
                      staticClass: "ma-0 pa-0",
                      attrs: {
                        bottom: "",
                        contained: "",
                        color: "#fff",
                        "nudge-top": -10,
                        "tooltip-opacity": "1",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mx-2 button-chip",
                                      attrs: { outlined: "", color: "primary" },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2 blue--text",
                                      attrs: { left: "" },
                                    },
                                    [_vm._v("mdi-information")]
                                  ),
                                  _c("span", { staticClass: "blue--text" }, [
                                    _vm._v("Color Codes"),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-weight-bold green--text text--darken-1",
                          },
                          [_vm._v("Driver Accepted")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-weight-bold blue--text text--darken-2",
                          },
                          [_vm._v("Driver Assigned (Pending Action)")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-weight-bold orange--text text--darken-3",
                          },
                          [_vm._v("No Driver Assigned")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-weight-bold red--text text-accent-2",
                          },
                          [_vm._v("Driver Declined")]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-menu",
                    {
                      attrs: { bottom: "", right: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        outlined: "",
                                        color: "grey darken-2",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.typeToLabel[
                                          _vm.vehicleCalendar.type
                                        ]
                                      )
                                    ),
                                  ]),
                                  _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v(" mdi-menu-down "),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleTypeChange("day")
                                },
                              },
                            },
                            [_c("v-list-item-title", [_vm._v("Day")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleTypeChange("week")
                                },
                              },
                            },
                            [_c("v-list-item-title", [_vm._v("Week")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleTypeChange("month")
                                },
                              },
                            },
                            [_c("v-list-item-title", [_vm._v("Month")])],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleTypeChange("4day")
                                },
                              },
                            },
                            [_c("v-list-item-title", [_vm._v("4 days")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-sheet",
            { attrs: { height: "950" } },
            [
              _c("v-calendar", {
                ref: "calendar",
                attrs: {
                  value: _vm.vehicleCalendar.focus,
                  color: "primary",
                  events: _vm.events,
                  "event-color": _vm.getEventColor,
                  type: _vm.vehicleCalendar.type,
                },
                on: {
                  "click:event": _vm.showEvent,
                  "click:more": _vm.viewDay,
                  "click:date": _vm.viewDay,
                  change: _vm.updateRange,
                },
              }),
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    activator: _vm.selectedElement,
                    "offset-x": "",
                  },
                  model: {
                    value: _vm.selectedOpen,
                    callback: function ($$v) {
                      _vm.selectedOpen = $$v
                    },
                    expression: "selectedOpen",
                  },
                },
                [
                  _vm.selectedEvent.tripData
                    ? _c(
                        "v-card",
                        {
                          attrs: {
                            color: "grey lighten-4",
                            "min-width": "350px",
                            flat: "",
                          },
                        },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                color: _vm.selectedEvent.color,
                                dark: "",
                              },
                            },
                            [
                              _c("v-toolbar-title", { staticClass: "w-full" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between mt-2",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.selectedEvent.vehicle)),
                                    ]),
                                    _c("div", {}, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "right",
                                          attrs: { outlined: "" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedEvent.id
                                                ? _vm.status(
                                                    _vm.selectedEvent.id
                                                  ).display
                                                : ""
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c("v-simple-table", {
                                staticClass: "pa-0 ma-0 grey lighten-4 mb-2",
                                attrs: { dense: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c(
                                            "tbody",
                                            {
                                              staticClass: "font-weight-medium",
                                            },
                                            _vm._l(
                                              _vm.selectedEvent.tripData,
                                              function (value, propertyName) {
                                                return _c(
                                                  "tr",
                                                  { key: propertyName + value },
                                                  [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(propertyName)
                                                      ),
                                                    ]),
                                                    _c("td", [
                                                      _vm._v(_vm._s(value)),
                                                    ]),
                                                    _c("v-divider"),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  773222347
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-space-between" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: { color: "error" },
                                  on: {
                                    click: function ($event) {
                                      _vm.selectedOpen = false
                                    },
                                  },
                                },
                                [_vm._v(" Close ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.selectedEvent.tripData &&
                                        _vm.canViewTrip(
                                          _vm.selectedEvent.tripRequestId
                                        ),
                                      expression:
                                        "selectedEvent.tripData && canViewTrip(selectedEvent.tripRequestId)",
                                    },
                                  ],
                                  staticClass: "ma-2",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push(
                                        `/trip-request/${_vm.selectedEvent.tripRequestId}?calendar=true&assignment=true`
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" View ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedEvent.dateData
                    ? _c(
                        "v-card",
                        {
                          attrs: {
                            color: "grey lighten-4",
                            "min-width": "350px",
                            flat: "",
                          },
                        },
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: {
                                color: _vm.selectedEvent.color,
                                dark: "",
                              },
                            },
                            [
                              _c("v-toolbar-title", { staticClass: "w-full" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedEvent.dateData.type ==
                                        "blocked"
                                        ? "Blocked Date"
                                        : "Special Date"
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c("v-simple-table", {
                                staticClass: "pa-0 ma-0 grey lighten-4",
                                attrs: { dense: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function () {
                                        return [
                                          _c(
                                            "tbody",
                                            {
                                              staticClass: "font-weight-medium",
                                            },
                                            [
                                              _c(
                                                "tr",
                                                [
                                                  _c("td", [
                                                    _vm._v("Description"),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.selectedEvent
                                                            .dateData
                                                            .description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("v-divider"),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "tr",
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      "Trip Types Effected"
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.selectedEvent.dateData.tripTypeIds
                                                            .map(
                                                              (e) =>
                                                                _vm
                                                                  .tripTypesById[
                                                                  e
                                                                ].name
                                                            )
                                                            .join(", ")
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("v-divider"),
                                                ],
                                                1
                                              ),
                                              _vm.selectedEvent.dateData
                                                .tripEventIds
                                                ? _c(
                                                    "tr",
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          "Trip Events Effected"
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.selectedEvent.dateData.tripEventIds
                                                                .map(
                                                                  (e) =>
                                                                    _vm
                                                                      .tripEventsById[
                                                                      e
                                                                    ].name
                                                                )
                                                                .join(", ")
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("v-divider"),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  839472453
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "justify-space-between" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: { color: "error" },
                                  on: {
                                    click: function ($event) {
                                      _vm.selectedOpen = false
                                    },
                                  },
                                },
                                [_vm._v(" Close ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.selectedEvent.tripData,
                                      expression: "selectedEvent.tripData",
                                    },
                                  ],
                                  staticClass: "ma-2",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$router.push(
                                        `/trip-request/${_vm.selectedEvent.tripRequestId}?calendar=true&assignment=true`
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" View ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }