var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-8", attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mb-6 mt-2",
          attrs: { height: "50", elevation: "4", rounded: "" },
        },
        [_c("CalendarMenu")],
        1
      ),
      _vm.active == "trip"
        ? _c("TripCalendar", {
            attrs: {
              blockedDateEvents: _vm.blockedDateEvents,
              specialDateEvents: _vm.specialDateEvents,
              loading: _vm.loading,
              updateRangeHandler: _vm.updateRangeHandler,
            },
            on: { changeDate: _vm.handleDateChange },
          })
        : _vm._e(),
      _vm.active == "vehicle"
        ? _c("VehicleCalendar", {
            attrs: {
              blockedDateEvents: _vm.blockedDateEvents,
              specialDateEvents: _vm.specialDateEvents,
              loading: _vm.loading,
              updateRangeHandler: _vm.updateRangeHandler,
            },
            on: { changeDate: _vm.handleDateChange },
          })
        : _vm._e(),
      _vm.active == "driver"
        ? _c("DriverCalendar", {
            attrs: {
              blockedDateEvents: _vm.blockedDateEvents,
              specialDateEvents: _vm.specialDateEvents,
              loading: _vm.loading,
              updateRangeHandler: _vm.updateRangeHandler,
            },
            on: { changeDate: _vm.handleDateChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }